/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";

// 3rd party libraries
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/@fortawesome/fontawesome-svg-core/styles.css";

// Theme customization
@import "theme/theme";

.content-container {
  padding: 20px 40px;
  background: #eff3f9;
}

.main-content {
  background: #eff3f9;
  padding-left: 0;
  padding-right: 0;

  .app-content {
    height: 100%;
    padding-bottom: 5rem;
  }
}

html,
body {
  font-size: 14px;
  background: $dark-blue;
  color: #607389;
  height: 100%;
  overflow: hidden;
}

header {
  padding: 10px;
}

footer {
  background: $light-blue;
  padding: 10px;
}

.btn-success {
  background: #68b935;
  border: none;
  font-size: 18px;
  padding: 10px 30px;
}

.invalid-feedback {
  font-size: 12px;
}

.filter-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

app-iframe-manager {
  background-color: #eff3f9;
}

// PRIMENG OVERRIDES
.p-datatable .p-datatable-header {
  color: $white !important;
  background: $light-blue !important;
}

.p-component {
  font-size: 14px !important;
}

.p-hidden-accessible {
  display: none !important;
}

.p-datatable .p-datatable-tbody > tr {
  cursor: pointer;
}

button.p-column-filter-menu-button,
button.p-column-filter-clear-button {
  display: none;
}

.p-inputtext {
  width: 100%;
}

.p-password {
  width: 100%;
}

.p-dropdown {
  width: 100%;
}

.p-card {
  border-radius: 0;
}

.p-splitbutton .p-tieredmenu {
  width: 100%;
}

.p-button {
  border-radius: 3px;
}

.p-card {
  border-radius: 3px;
}

ul {
  padding-left: 0;
  margin-bottom: 0;
}

a {
  color: unset;
  text-decoration: none;
}
